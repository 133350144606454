import React from "react"
import useSiteMetadata from "../hooks/useSiteMetadata"

interface SeoProps {
  title: string
  noIndex?: boolean
  description?: string
  image?: string
  children?: React.ReactNode
  canonical?: string
}

const SEO = ({
  title,
  noIndex = false,
  description,
  children,
  canonical,
}: SeoProps) => {
  const { image, siteUrl } = useSiteMetadata()
  return (
    <>
      <meta charSet="utf-8" />
      {!!canonical && <link rel="canonical" href={canonical} />}
      <title>{title}</title>
      {description && (
        <meta
          name="description"
          property="og:description"
          content={description}
        />
      )}
      {noIndex && <meta name="robots" content="noindex" />}
      <meta property="og:title" content={title} />
      <meta name="twitter:card" content="summary" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonical} />
      <meta property="og:image" content={`${siteUrl}${image}`} />
      <meta name="twitter:title" content={title} />
      {description && <meta name="twitter:description" content={description} />}
      <meta name="twitter:image" content={`${siteUrl}${image}`} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      {children || null}
    </>
  )
}

export default SEO
