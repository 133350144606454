import { graphql, HeadFC } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useCallback, useEffect, useState } from "react"
import Contact from "../components/Contact"
import SEO from "../components/SEO"
import useSiteMetadata from "../hooks/useSiteMetadata"

const CaseStudyPage = ({ data }) => {
  const {
    allCaseStudyJson: { nodes: CaseStudy },
  } = data
  const [categories, setCategories] = useState([""])
  const [activeCategory, setActiveCategory] = useState(CaseStudy[0].category)
  const [categoryInfo, setCategoryInfo] = useState(CaseStudy)
  useEffect(() => {
    setCategories([...new Set<string>(CaseStudy.map(node => node.category))])
  }, [])

  const getActiveCategoryClass = useCallback(
    category => {
      if (category === activeCategory) {
        return `bg-[#EEEEEE]`
      }
      return ""
    },
    [activeCategory]
  )

  return (
    <>
      <section className="py-[5.625rem]">
        <div className="container">
          <div className="title-sec pb-[3.75rem] w-full max-w-[61.25rem] m-auto relative before:w-[14.25rem] before:h-[14.25rem] before:rounded-full before:bg-[#FFF6EA] before:absolute before:-z-10 before:left-[-5rem] before:top-[-4.25rem]">
            <h6 className="text-primary text-base pb-5 font-gorditabold">
              Case Studies
            </h6>
            <h2 className="md:text-[2.5rem] text-[2rem] md:pb-1 pb-1.5 font-blancotrialbold md:leading-[3.75rem] leading-[2.4rem]">
              The case studies that describe how we deliver successful products
              <span className="bg-orangecol w-2 h-2 rounded-full inline-block ml-1"></span>
            </h2>
            <p className="text-base md:font-gorditamedium font-gorditaregular leading-[1.7rem] text-grey">
              Problems are not 'Stop' signs, they are guidelines. Read our
              success stories in delivering custom software solutions to diverse
              businesses.
            </p>
          </div>
          <div className="tabbing w-full max-w-[61.25rem] m-auto">
            <ul className="md:flex border-b border-b-[#E6E6E6] pb-5 mb-2 hidden">
              {categories.map((category, idx) => (
                <li
                  className={`cursor-pointer text-sm font-gorditamedium text-[#757575] px-3 py-2 mr-3 rounded transition-all duration-300 ${getActiveCategoryClass(
                    category
                  )} hover:text-black hover:bg-[#EEEEEE]`}
                  onClick={() => setActiveCategory(category)}
                  key={`${idx}- ${category}`}
                >
                  {category}
                </li>
              ))}
            </ul>
            <select
              onChange={event => setActiveCategory(event.target.value)}
              value={activeCategory}
              className="md:hidden block w-full border border-[#C8CBD2] rounded-md text-black py-3.5 px-5 font-gorditamedium text-[0.938rem] appearance-none bg-[url('../assets/img/down-arrow-orange.svg')] bg-no-repeat bg-[center_right_1.25rem] bg-[length:13px_20px]"
            >
              {categories.map((category, idx) => (
                <option key={`${idx}-${category}`} value={category}>
                  {category}
                </option>
              ))}
            </select>
            <div className="case-study-catgory flex flex-col">
              {categoryInfo.map(
                (
                  {
                    category,
                    slug,
                    featuredImage,
                    alt,
                    title,
                    description,
                    tags,
                    analytics,
                  },
                  idx
                ) => {
                  featuredImage = getImage(featuredImage)
                  if (activeCategory === category)
                    return (
                      <div
                        key={`${idx}- ${category}`}
                        className="bg-white-100 md:p-10 p-5 border border-[#E5E6E5] rounded mt-16 flex items-center justify-between flex-wrap md:flex-nowrap"
                      >
                        <div className="md:pr-14 pr-0 md:md:w-7/12 w-full md:order-1 order-2">
                          <h3 className="text-2xl font-blancotrialbold leading-[2.375krem] pb-3">
                            {title}
                          </h3>
                          <p className="text-sm leading-6 text-grey pb-5">
                            {description}
                          </p>
                          <ul className="flex flex-wrap">
                            {tags.map((tag, idx) => (
                              <li
                                key={`${idx}-${tag}`}
                                className="text-xs text-grey pt-2 font-gorditamedium px-6 py-2 bg-white-200 rounded-3xl mr-2.5 mb-2.5"
                              >
                                {tag}
                              </li>
                            ))}
                          </ul>
                          <ul className="flex pt-7">
                            {analytics.map(
                              ({ key, figure, description }, idx) => {
                                return (
                                  <li
                                    key={`${idx}-${key}`}
                                    className="w-40 md:mr-12 mr-6"
                                  >
                                    <h4 className="text-5xl font-gorditabold text-primary">
                                      {figure}
                                    </h4>
                                    <p className="text-xs text-grey pt-2 leading-5">
                                      {description}
                                    </p>
                                  </li>
                                )
                              }
                            )}
                          </ul>
                        </div>
                        <GatsbyImage
                          image={featuredImage}
                          alt={alt}
                          className="md:md:w-5/12 w-full md:order-2 order-1 md:mb-0 mb-7 md:h-[21.875rem] h-[15.875rem] object-cover"
                        />
                      </div>
                    )
                }
              )}
            </div>
          </div>
        </div>
      </section>
      <Contact />
    </>
  )
}

export const portfolioQuery = graphql`
  query {
    allCaseStudyJson {
      nodes {
        category
        slug
        title
        description
        tags
        featuredImage {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        alt
        analytics {
          key
          figure
          description
        }
      }
    }
  }
`

export default CaseStudyPage

export const Head: HeadFC = ({ location }) => {
  const { siteUrl } = useSiteMetadata()
  return (
    <SEO
      title="Case study | Techuz Infoweb"
      canonical={`${siteUrl}${location.pathname}`}
      description="Techuz Infoweb case-study page"
    />
  )
}
